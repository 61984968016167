<template>
  <v-row no-gutters>
  </v-row>
</template>

<script>

export default {
  components: {
  },
  setup() {
    return {}
  },
  mounted(){
    if(this.$route.name === 'join-talent'){
      window.location.href = 'https://vicedigitalcreative.com/for_influencers/'
      // window.open('https://vicedigitalcreative.com/for_influencers/');
      // this.$router.push({name:'home'}); 
    } else if(this.$route.name === 'term-conditions'){
      window.open('https://vicedigitalcreative.com/terms_conditions', '_blank');
      this.$router.push({name:'home'}); 
    } else if(this.$route.name === 'privacy-policy'){
      window.open('https://vicedigitalcreative.com/privacy_policy', '_blank');
      this.$router.push({name:'home'}); 
    } else if(this.$route.name === 'about'){
      window.location.href = 'https://vicedigitalcreative.com/'
      // window.open('https://vicedigitalcreative.com', '_blank');
      // this.$router.push({name:'home'}); 
    } else if(this.$route.name === 'faq'){
      window.open('https://vicedigitalcreative.com/faq', '_blank');
      this.$router.push({name:'home'}); 
    } else {
      this.$router.push({name:'home'});
    }
  },
}
</script>
